import React from 'react'
import '../../css/reset.css'
import '../../css/style.css'
import html5 from '../../assets/img/html5.svg';
import javascript from '../../assets/img/javascript.svg';
import css3 from '../../assets/img/css3.svg';
import react from '../../assets/img/react.svg';
import node from '../../assets/img/node.svg';
import sass from '../../assets/img/sass.svg';
const Skills = () => {
  return (
    <section id="skills">
      <h1 className="skills__titulo">Minhas skills</h1>
      <section className="skills">
        <article className="skills__container"> 
          <div className="skills__thumbnail">
            <div className="skills__icon">
              <div className="skills__icon-fundo">
                <img src={html5} alt="" />
              </div>
            </div>
          </div>
        </article>
        <article className="skills__container"> 
          <div className="skills__thumbnail">
            <div className="skills__icon">
              <div className="skills__icon-fundo">
                <img src={css3} alt="" />
              </div>
            </div>
          </div>
        </article>
        <article className="skills__container"> 
          <div className="skills__thumbnail">
            <div className="skills__icon">
              <div className="skills__icon-fundo">
                <img src={javascript} alt="" />
              </div>
            </div>
          </div>
        </article>
        <article className="skills__container"> 
          <div className="skills__thumbnail">
            <div className="skills__icon">
              <div className="skills__icon-fundo">
                <img src={node} alt="" />
              </div>
            </div>
          </div>
        </article>
        <article className="skills__container"> 
          <div className="skills__thumbnail">
            <div className="skills__icon">
              <div className="skills__icon-fundo">
                <img src={react} alt="" />
              </div>
            </div>
          </div>
        </article>
        <article className="skills__container"> 
          <div className="skills__thumbnail">
            <div className="skills__icon">
              <div className="skills__icon-fundo">
                <img src={sass} alt="" />
              </div>
            </div>
          </div>
        </article>
      </section>
    </section>
  )
}

export default Skills
