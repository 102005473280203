import React from 'react'
import '../../css/reset.css'
import '../../css/style.css'
const About = () => {
  return (
    <section id="about" className="about">
      <article className="about__container">
        <h1 className="about__titulo">Sobre mim</h1>
        <p className="about__descricao">Desenvolvedor Web há 4 anos e apaixonado por tecnologia. Durante minha trajetória, desenvolvi websites e sistemas utilizando tecnologias com <strong>foco em front-end</strong> e por vezes back-end, juntamente com <strong>metodologias ágeis</strong> para organizar as entregas. Busco sempre transformar <strong>idéias</strong> em <strong>códigos</strong> e problemas em <strong>soluções</strong>.
        </p>
      </article>
    </section>
  )
}

export default About
