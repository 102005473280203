import React from 'react'
import logo from '../../assets/img/banner.png'
import '../../css/reset.css'
import '../../css/style.css'
import curriculo from '../../assets/curriculo/Curriculo_JOAO_PORFIRIO.pdf'
const Main = () => {
  return (
    <main className="main">
      <section className="main__container">
        <article className="main__informacoes">
          <div>
            <h1 className="main__titulo">Olá, eu sou o <br/>João Porfirio</h1>
            <p className="main__subtitulo">Desenvolvedor Fullstack</p>
          </div>
          <div className="main__button-container">
            <a href={curriculo} download>
              <button className="main__botao-cv">Download CV</button>
            </a>
            <a href="#form">
              <button className="main__botao-contato">Entrar em contato
              </button>
            </a>
          </div>
        </article>
        <article className="main__banner">
          <picture>
            <img src={logo} alt="" />
          </picture>
        </article>
      </section>
    </main>
  )
}

export default Main
