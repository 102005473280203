import React from 'react'
import '../../css/reset.css'
import '../../css/style.css'
const Footer = () => {
  return (
    <footer className="footer">
      <article className="footer__container">
        <span className="footer__copyright">João Porfirio &nbsp;©&nbsp; Todos os direitos reservados.</span>
      </article>
    </footer>
  )
}

export default Footer
