/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import '../../css/reset.css'
import '../../css/style.css'
import menuHamburger from '../../assets/img/hamburguer.png';

const Header = () => {

	const [responsive, setResponsive] = React.useState(false);
	const [visible, setVisible] = React.useState(false);

	const handleClose = (event) => {
		setResponsive(prev => !prev);
		if(event.target.tagName === "IMG") setVisible(vis => !vis);
		else{ if(visible) setVisible(false);}
	}

	return (
		<header className="header">
			<nav className="header__navbar navbar">
				<div className={responsive ? 'topnav responsive' : 'topnav'}>
					<div className="navbar__div">
						<ul className="navbar__itens">
							<li className={`navbar__logo ${visible ? 'dn' : ''}`}>Portfólio</li>
						</ul>
					</div>

					<div className="navbar__div dn"></div>

					<div className="navbar__div">
						<ul className="navbar__itens">
							<a href="#about">
								<li className="navbar__item" onClick={handleClose}>
									Sobre mim
								</li>
							</a>
							<a href="#projetos">
								<li className="navbar__item" onClick={handleClose}>Projetos</li>
							</a>
							<a href="#servicos">
								<li className="navbar__item" onClick={handleClose}>Serviços</li>
							</a>
							<a href="#skills">
								<li className="navbar__item" onClick={handleClose}>Minhas skills</li>
							</a>
							<a className='icon' onClick={handleClose}>
								<img className="hamburguer" alt="hamburguer icon" src={menuHamburger} />
							</a>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	)
}

export default Header