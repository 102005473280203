import React, { useState } from 'react'
import axios from 'axios'
import 'sweetalert2/dist/sweetalert2'
import '../../css/reset.css'
import '../../css/style.css'


const Formulario = () => {
	const [nome, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [telefone, setTelefone] = useState('');
	const [mensagem, setMensagem] = useState('');

	const Swal = require('sweetalert2')

	const mensagemSucesso = () => {
		Swal.fire(
			'Muito obrigado!',
			'Seu e-mail foi enviado com sucesso.',
			'success'
		).then(function () {
			setNome('');
			setEmail('');
			setTelefone('');
			setMensagem('');
		})
	}
	const mensagemErro = () => {
		Swal.fire(
			'Ops!',
			'Houve um problema com o envio do e-mail... Entre em contato comigo usando o número de telefone!',
			'error'
		)
	}


	const sendEmail = (e) => {
		e.preventDefault();	
		axios.post(`https://enviaremaildoportfolio.herokuapp.com/sendEmail/${nome}/${email}/${telefone}/${mensagem}/`)
			.then(()=> {
				mensagemSucesso();
			})
			.catch(()=> {
				mensagemErro();			
			})
	};

	return (
		<section id="form" className="div">
			<h2 className="div__titulo">Entre em contato comigo!</h2>
			<form onSubmit={sendEmail} className="div__formulario .formulario">
				<ul className="formulario__container">
					<li className="formulario__container-item">
						<input className="formulario__field" type="text" id="first-name" name="nome" placeholder="Nome" value={nome} onChange={(e) => setNome(e.target.value)} required />
					</li>
					<li className="formulario__container-item">
						<input className="formulario__field" type="email" id="email" name="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)}
							required />
					</li>
					<li className="formulario__container-item">
						<input className="formulario__field" type="tel" id="phone" name="telefone" placeholder="Telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)}
							required />
					</li>
					<li className="formulario__container-item">
						<textarea className="formulario__field" rows="5" id="message" name="message" placeholder="Mensagem..." value={mensagem} onChange={(e) => setMensagem(e.target.value)}
							required></textarea>
					</li>
					<li className="formulario__container-item">
						<button className="formulario__container-button" type="submit">Enviar</button>
					</li>
				</ul>
			</form>
		</section>
	)
}

export default Formulario
