import React from 'react'
import '../../css/reset.css'
import '../../css/style.css'
// import appQueimadas from 'https://drive.google.com/drive/folders/1cbckRbWY-WR_MQJ5YHA0newkrTCxd67l?usp=sharing'
import appDeputados from '../../assets/app/informacoesDeputados.apk'

const Projetos = () => {
  return (
    <section id="projetos">
      <h1 className="projetos__titulo">Projetos</h1>
      <section className="projetos">     
        
      <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a className="projetos__content-descricao" target="__blank" href="https://feriadosbrasileiros.netlify.app/">
              <div className="projetos__feriados"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a className="projetos__content-descricao" target="__blank" href="https://feriadosbrasileiros.netlify.app/">
              <h2 className="projetos__content-titulo">Portal e API de Feriados brasileiros</h2>
            </a>
            <a className="projetos__content-descricao" target="__blank" href="https://feriadosbrasileiros.netlify.app/">Clique aqui para conhecer</a>
          </div>  
        </article>

        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a className="projetos__content-descricao" target="__blank" href="https://calculartintas.netlify.app/">
              <div className="projetos__calcular-tintas"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a className="projetos__content-descricao" target="__blank" href="https://calculartintas.netlify.app/">
              <h2 className="projetos__content-titulo">Sistema para Cálculo de Pinturas de Parede</h2>
            </a>
            <a className="projetos__content-descricao" target="__blank" href="https://calculartintas.netlify.app/">Clique aqui para conhecer</a>
          </div>  
        </article>

        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
          <a className="projetos__content-descricao" target="__blank" href="https://jordan-shoes-reactjs.netlify.app/">
            <div className="projetos__jordan"></div>
          </a>
          </div>
          <div className="projetos__content">
            <a className="projetos__content-descricao" target="__blank" href="https://jordan-shoes-reactjs.netlify.app/">
              <h2 className="projetos__content-titulo">Loja de Tênis Jordan</h2>
            </a>
            <a className="projetos__content-descricao" target="__blank" href="https://jordan-shoes-reactjs.netlify.app/">Clique aqui para conhecer</a>
          </div>  
        </article>

        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a href="https://drive.google.com/drive/folders/1cbckRbWY-WR_MQJ5YHA0newkrTCxd67l?usp=sharing" className="projetos__content-descricao" download>  
              <div className="projetos__queimadas"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a href="https://drive.google.com/drive/folders/1cbckRbWY-WR_MQJ5YHA0newkrTCxd67l?usp=sharing" className="projetos__content-descricao" download>  
              <h2 className="projetos__content-titulo">Aplicativo de Monitoramento de Queimadas no Brasil</h2>
            </a>
            <a href="https://drive.google.com/drive/folders/1cbckRbWY-WR_MQJ5YHA0newkrTCxd67l?usp=sharing" className="projetos__content-descricao" download>Clique aqui para instalar o aplicativo em dispositivos Android</a>
          </div>  
        </article>
        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a className="projetos__content-descricao" target="__blank" href="https://buscarusuariosdogithub.netlify.app/">
              <div className="projetos__busca-github"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a className="projetos__content-descricao" target="__blank" href="https://buscarusuariosdogithub.netlify.app/">
              <h2 className="projetos__content-titulo">Buscar Usuários do Github</h2>
            </a>
            <a className="projetos__content-descricao" target="__blank" href="https://buscarusuariosdogithub.netlify.app/">Clique aqui para conhecer</a>
          </div>  
        </article>
        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a className="projetos__content-descricao" target="__blank" href="https://gerarqrcode.netlify.app/">
              <div className="projetos__gerador-qrcode"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a className="projetos__content-descricao" target="__blank" href="https://gerarqrcode.netlify.app/">
              <h2 className="projetos__content-titulo">Gerador de QR Code</h2>
            </a>
            <a className="projetos__content-descricao" target="__blank" href="https://gerarqrcode.netlify.app/">Clique aqui para conhecer</a>
          </div>  
        </article>
        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a href={appDeputados} className="projetos__content-descricao" target="__blank" download>  
              <div className="projetos__deputados-brasileiros"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a href={appDeputados} className="projetos__content-descricao" target="__blank" download>  
              <h2 className="projetos__content-titulo">Aplicativo de Informações dos Deputados Brasileiros</h2>
            </a>
            <a href={appDeputados} className="projetos__content-descricao" target="__blank" download>Clique aqui para instalar o aplicativo em dispositivos Android</a>
          </div>  
        </article>
        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a className="projetos__content-descricao" target="__blank" href="https://buscarcepvuejs.netlify.app/">
              <div className="projetos__busca-cep"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a className="projetos__content-descricao" target="__blank" href="https://buscarcepvuejs.netlify.app/">
              <h2 className="projetos__content-titulo">Buscador de Endereços a partir do CEP</h2>
            </a>
            <a className="projetos__content-descricao" target="__blank" href="https://buscarcepvuejs.netlify.app/">Clique aqui para conhecer</a>
          </div>  
        </article>

        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <a className="projetos__content-descricao" target="__blank" href="https://blogminimalista.netlify.app/">
              <div className="projetos__blog"></div>
            </a>
          </div>
          <div className="projetos__content">
            <a className="projetos__content-descricao" target="__blank" href="https://blogminimalista.netlify.app/">
              <h2 className="projetos__content-titulo">Blog Minimalista</h2>
            </a>
            <a className="projetos__content-descricao" target="__blank" href="https://blogminimalista.netlify.app/">Clique aqui para conhecer</a>
          </div>  
        </article>
        <article className="projetos__container"> 
          <div className="projetos__thumbnail">
            <div className="projetos__desenvolvimento"></div>
          </div>
          <div className="projetos__content">
            <h2 className="projetos__content-titulo">Em desenvolvimento</h2>
            <p className="projetos__content-descricao" target="__blank" >Aguarde! Novidades em breve...</p>
          </div>  
        </article>
      </section>
    </section>
  )
}

export default Projetos
