import React from 'react'
import '../../css/reset.css'
import '../../css/style.css'
import smartphone from '../../assets/img/smartphone.svg';
import code from '../../assets/img/code.svg';
import node from '../../assets/img/node.svg';
const Servicos = () => {
  return (
    <section id="servicos">
      <h1 className="servicos__titulo">Serviços</h1>
      <section className="servicos">     
        <article className="servicos__container"> 
          <div className="servicos__content">
            <div className="servicos__icon">
              <img src={code} alt="" />
            </div>
            <h2 className="servicos__content-titulo">Criação<br/> de sites</h2>
          </div>  
        </article>
        <article className="servicos__container"> 
          <div className="servicos__content">
            <div className="servicos__icon">
              <img src={smartphone} alt="" />
            </div>
            <h2 className="servicos__content-titulo">Criação<br/> de aplicativos</h2>
          </div>  
        </article>
        <article className="servicos__container"> 
          <div className="servicos__content">
            <div className="servicos__icon">
              <img src={node} alt="" />
            </div>
            <h2 className="servicos__content-titulo">Criação<br/> de API</h2>
          </div>  
        </article>
      </section>
    </section>  
  )
}

export default Servicos
