import React from 'react'
import '../../css/reset.css'
import '../../css/style.css'
import linkedin from '../../assets/img/linkedin.svg';
import github from '../../assets/img/github.svg';
import mail from '../../assets/img/mail.svg';
import phone from '../../assets/img/phone.svg';
const Informacoes = () => {
	return (
		<section className="informacoes">
			<article className="informacoes__container">
				<div className="informacoes__thumbnail">
					<div className="informacoes__icon">
						<div className="informacoes__icon-fundo">
							<a href="https://www.linkedin.com/in/joao-porfirio-68ba97187/" target="__blank">
								<img src={linkedin} alt="" />
							</a>
						</div>
					</div>
				</div>
					<div className="informacoes__content">
						<a href="https://www.linkedin.com/in/joao-porfirio-68ba97187/" target="__blank">
							<h2 className="informacoes__content-titulo">Linkedin</h2>
						</a>
						<a href="https://www.linkedin.com/in/joao-porfirio-68ba97187/" target="__blank">
							<p className="informacoes__content-descricao">@joaoporfirio</p>
						</a>
				</div>
			</article>
			<article className="informacoes__container">
				<div className="informacoes__thumbnail">
					<div className="informacoes__icon">
						<div className="informacoes__icon-fundo">
							<a href="mailto:joaoporfiriocp@gmail.com">
								<img src={mail} alt="" />
							</a>
						</div>
					</div>
				</div>
				<div className="informacoes__content">
					<a href="mailto:joaoporfiriocp@gmail.com">
						<h2 className="informacoes__content-titulo">E-mail</h2>
					</a>
					<a href="mailto:joaoporfiriocp@gmail.com">
						<p className="informacoes__content-descricao">joaoporfiriocp@gmail.com</p>
					</a>
				</div>
			</article>
			<article className="informacoes__container">
				<div className="informacoes__thumbnail">
					<div className="informacoes__icon">
						<div className="informacoes__icon-fundo">
							<a href="https://github.com/joao-porfirio" target="__blank">
								<img src={github} alt="" />
							</a>
						</div>
					</div>
				</div>
				<div className="informacoes__content">
					<a href="https://github.com/joao-porfirio" target="__blank">
						<h2 className="informacoes__content-titulo">Github</h2>
					</a>
					<a href="https://github.com/joao-porfirio" target="__blank">
						<p className="informacoes__content-descricao">@joao-porfirio</p>
					</a>
				</div>
			</article>
			<article className="informacoes__container">
				<div className="informacoes__thumbnail">
					<div className="informacoes__icon">
						<div className="informacoes__icon-fundo">
							<a href="https://wa.me/5511943748130?text=Olá%20João,%20observei%20seu%20portfólio%20e%20gostaria%20de%20mais%20informações!" target="__blank">
								<img src={phone} alt="" />
							</a>
						</div>
					</div>
				</div>
				<div className="informacoes__content">
					<a href="https://wa.me/5511943748130?text=Olá%20João,%20observei%20seu%20portfólio%20e%20gostaria%20de%20mais%20informações!" target="__blank">
						<h2 className="informacoes__content-titulo">Telefone</h2>
					</a>
					<a href="https://wa.me/5511943748130?text=Olá%20João,%20observei%20seu%20portfólio%20e%20gostaria%20de%20mais%20informações!" target="__blank">
						<p className="informacoes__content-descricao">(11) 9 4374-8130 </p>
					</a>
				</div>
			</article>
		</section>
	)
}

export default Informacoes
